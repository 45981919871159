<template>
  <v-container fluid id="PessoaConsulta" class="container-fundo pa-0"  v-bind:style="{ 'background-color':COR_PRINCIPAL }">
    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
        >Pessoas
      </v-toolbar-title>
      <!-- Fim Título da página -->

      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title
        class="mt-0 mb-10 ml-4 pa-0 d-flex flex-column w-100"
        dark
        elevation="0"
      >
        <div class="d-flex align-center w-100" tabindex="-1">
          <v-text-field
          id="txtBuscar"
          ref="txtBuscar"
          autofocus
          v-on:keyup="store_Pessoa.filtro = $event.target.value"
          prepend-inner-icon="mdi-magnify"
          dense
          v-model="store_Pessoa.search"
          cache-items
          class="search-input"
          v-bind:class="{'search-input-longo': search_input_focus}"
          flat
          dark
          label=""
          solo-inverted
          @focus="onFocus($event)"
          @blur="onBlur($event)"
          tabindex="-1"
          >
          </v-text-field>
            <router-link
              :to="{ name: 'PessoaContainer' }"
              class="nav-link"
              aria-current="page"
              tabindex="-1"
            >
              <v-btn 
                class="text-white text-capitalize"
                :color="COR_PRINCIPAL"
                elevation="0"
                :small="isMobile"
                :disabled="!store_usuario.priv_busca_privilegios_aplicacao_evento('Pessoa.Cadastrar')">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span>Novo</span>
              </v-btn>
            </router-link>

            <v-btn class="text-white text-capitalize mr-2" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
              <v-icon class="mr-1" color="light-blue accent-2"
                >mdi-view-headline</v-icon
              >
              <span>Opções</span>
            </v-btn>
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <v-row class=" mt-n11 my-n2 w-100 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100">
        <v-container class="container-principal container-rounded px-0 w-100 mx-0">

            <!-- Conteiner  -->
            <v-data-table
              id="dataTable"
              ref="dataTable"
              :items="store_Pessoa.dados"
              :headers="headers"
              :loading="loading"
              fixed-header
              dense
              :height="tableHeight"
              :hide-default-header="isMobile"
              :hide-default-footer="true"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table"
              :server-items-length="store_Pessoa.count_dados"
              :items-per-page="20"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right',
                    'disable-items-per-page': true,
                    showItemsPerPage: false,
                    'items-per-page-text': '',
                    'rows-per-page-items':null
                  }"
              :page.sync="store_Pessoa.currentPage" 
              @update:page="handlePageChange"
            >
              <template #item="{ item }">
                <tr v-if="!isMobile">
                  <td style="width: 5%; padding: 0;">
                    <v-menu
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-2"
                        >
                        
                          <v-list-item-title
                            v-if="item_menu.title == 'Editar'"
                          >
                            <router-link :to="'/pessoacontainer/' + item.cod_pessoa"
                            class="nav-link"
                            aria-current="page"
                            >
                              <v-icon color="green" class="mr-2 icon-menu"
                                >mdi-pencil-outline
                              </v-icon>
                            {{ item_menu.title }}
                            </router-link>
                          </v-list-item-title>

                          <v-list-item-title
                          v-else-if="item_menu.title == 'Excluir'"
                          >
                            <div @click="dialog_excluir = true; item_pessoa_excluir_aux = item">
                              <v-icon color="red" class="mr-2 icon-menu"
                              >mdi-delete-outline</v-icon>

                              {{ item_menu.title }}
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td style="width: 8%;" class="td-avatar py-1 px-0">
                    <v-avatar 
                      v-if="item.pessoa_foto"
                      size="50">
                      <v-img :src="item.pessoa_foto"> </v-img>
                    </v-avatar>
                    <v-avatar
                      v-else
                      color="#D0D0D0"
                      rounded="false"
                      size="50"
                    >
                      <span color="#274ABB" class="headline">
                        {{ nomeIniciais(item.pessoa_nome) }}
                      </span>
                    </v-avatar>
                  </td>

                  <td style="width: 35%">
                    <span style="font-size: 15px !important;" class="font-weight-bold">{{ item.pessoa_nome }}<br></span>
                    <span>{{ item.pessoa_email }}</span>
                  </td>

                  <td v-if="item.pessoa_cpf" style="width: 20%">
                    {{ maskCpfCnpj(item.pessoa_cpf) }}
                  </td>
                  <td v-if="item.pessoa_cnpj" style="width: 20%">
                    {{ maskCpfCnpj(item.pessoa_cnpj) }}
                  </td>

                  <td style="width: 20%">
                    {{ item.fone_celular1 }}
                  </td>

                  <!-- <td class="td-edit" style="width: 3%">
                    <router-link
                      :to="'/pessoacontainer/' + item.cod_pessoa"
                      class="nav-link"
                      aria-current="page"
                      tabindex="-1"
                    >
                      <v-btn icon dark class="btn-icon mr-5" color="grey">
                        <v-icon class="icon-action"> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </router-link>
                  </td> -->
                </tr> 

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile">
                  <td style="width: 5%;">
                    <v-menu
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-2"
                        >
                        
                          <v-list-item-title
                            v-if="item_menu.title == 'Editar'"
                          >
                            <router-link :to="'/pessoacontainer/' + item.cod_pessoa"
                            class="nav-link"
                            aria-current="page"
                            >
                              <v-icon color="green" class="mr-2 icon-menu"
                                >mdi-pencil-outline
                              </v-icon>
                            {{ item_menu.title }}
                            </router-link>
                          </v-list-item-title>

                          <v-list-item-title
                          v-else-if="item_menu.title == 'Excluir'"
                          >
                            <div @click="dialog_excluir = true; item_pessoa_excluir_aux = item">
                              <v-icon color="red" class="mr-2 icon-menu"
                              >mdi-delete-outline</v-icon>

                              {{ item_menu.title }}
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td style="border:0">
                    <div style="width:25px !important" class="ml-n2">
                      <v-avatar 
                        v-if="item.pessoa_foto"
                        size="50">
                        <v-img :src="item.pessoa_foto"> </v-img>
                      </v-avatar>
                    <v-avatar
                      v-else
                      color="#D0D0D0"
                      rounded="false"
                      size="50"
                    >
                      <span color="#274ABB" class="headline">
                        {{ nomeIniciais(item.pessoa_nome) }}
                      </span>
                    </v-avatar>
                    
                    </div>
                  </td>

                  <td style="width: 100%" >
                    <div style="font-size: 15px; display:block"              class="font-weight-bold">{{ item.pessoa_nome }}</div>
                    <div style="font-size: 13px; color:#808080 !important; " class="mt-0" >email: {{ item.pessoa_email }}</div>
                    <div v-if="item.pessoa_cpf" style="font-size: 13px; color:#808080 !important; " class="mt-0">
                      cpf: {{ maskCpfCnpj(item.pessoa_cpf) }}
                    </div>
                    <div v-if="item.pessoa_cnpj" style="font-size: 13px; color:#808080 !important; " class="mt-0">
                      cnpj: {{ maskCpfCnpj(item.pessoa_cnpj) }}
                    </div>
                    <div style="font-size: 13px; color:#808080 !important; " class="mt-0 mb-2" >celular: {{ item.fone_celular1 }}</div>
                  </td>

                  <!-- <td>
                    <div style="width:25px !important" class="ml-n4">
                    <router-link
                      :to="{
                        name: 'PessoaContainer',
                        params: { cod_pessoa: item.cod_pessoa },
                      }"
                      class="nav-link"
                      aria-current="page"
                    >
                      <v-btn icon dark class="btn-icon" color="grey">
                        <v-icon class="icon-action"> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </router-link>
                    </div>
                  </td> -->
                </tr>
              </template>
            <!-- ------------------------------------------------------------------------------------------------------------------------------ -->
            </v-data-table>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <!-- Paginacao ------------------------------ -->
    <div class="paginacao d-flex justify-end align-center" style="background-color: #FFFFFF;">
      <span style="color:grey; font-size:14px" class="mr-3">{{store_Pessoa.count_dados}} linha(s)</span>
      <v-pagination
        v-model="store_Pessoa.currentPage"
        :length="store_Pessoa.paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
      />
    </div>

    <!-- dialogo de exclusão -->
    <v-container v-if="dialog_excluir">
      <v-dialog
        v-model="dialog_excluir"
        persistent
        max-width="430"
        class="d-flex justify-center"
      >
          <v-divider></v-divider>
  
          <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
          <v-card-title class="text-h6">
            Tem certeza que deseja EXCLUIR ?
          </v-card-title>
          <v-card-text v-if="item_pessoa_excluir_aux.pessoa_cpf">
            <span style="font-size: 15px; font-weight:600">{{item_pessoa_excluir_aux.pessoa_nome}}</span><br>
            {{maskCpfCnpj(item_pessoa_excluir_aux.pessoa_cpf)}}<br>
            {{item_pessoa_excluir_aux.pessoa_email}}<br>
            {{item_pessoa_excluir_aux.fone_celular1}}<br>
          </v-card-text>
          <v-card-text v-if="item_pessoa_excluir_aux.pessoa_cnpj">
            <span style="font-size: 15px; font-weight:600">{{item_pessoa_excluir_aux.pessoa_nome}}</span><br>
            {{maskCpfCnpj(item_pessoa_excluir_aux.pessoa_cnpj)}}<br>
            {{item_pessoa_excluir_aux.pessoa_email}}<br>
            {{item_pessoa_excluir_aux.fone_celular1}}<br>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
              <v-btn
                id="btn_cancelar"
                ref="btn_cancelar"
                class="mr-4"
                color="primary"
                text
                @click="dialog_excluir = false"
              >
                Cancelar
              </v-btn>
  
              <v-btn
                class="btn white--text"
                color="primary accent-4"
                :loading="loading_excluir"
                @click="excluir(item_pessoa_excluir_aux.cod_pessoa)"
              >
                Excluir
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- fim do dialogo de exclusão -->

  </v-container>
</template>

<script>
import store_usuario from "../../store/store_usuario";
import store_Pessoa from "./store_Pessoa";
import { nomeIniciais } from "../../services/funcoes"
import { COR_PRINCIPAL } from "../../services/constantes";
import { maskCpfCnpj } from "../../services/funcoes";
import store_site from "../../store/store_site";

export default {
  name: "PessoaConsulta",
  components: {
  },

  data() {
    return {
      store_usuario   : store_usuario,
      store_Pessoa    : store_Pessoa,
      store_site      : store_site,


      nomeIniciais    : nomeIniciais,
      maskCpfCnpj     : maskCpfCnpj,

      loading             : false,
      search_input_focus  : false,

      loading_excluir     : false,
      item_pessoa_excluir_aux : null,

      COR_PRINCIPAL           : COR_PRINCIPAL,
      accentColor             : '#f00',
      dialog_excluir          : false,
      dialog_excluir_alert    : false,
      dialog_excluir_msg      : false,
      dialog_excluir_message  : null,
      
      /* Título container-rounded */
      headers: [  { text: "", sortable: false },
                  { text: "", value: "", sortable: true },
                  { text: "Nome", value: "nome", sortable: true },
                  { text: "CPF/CNPJ", value: "cpf_cnpj" },
                  { text: "Telefone", value: "telefone" },
                  { text: ""},
               ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir", 
        },
      ],

    };
  },

  async created() {
    if (this.store_Pessoa.dados.length <= 0)
      this.busca();
  },

  watch: {
    'store_Pessoa.search'(val) {
      this.store_Pessoa.filtro = val;
      this.store_Pessoa.currentPage = 1;
      this.busca_timeout(val);
    },
    // 'store_Pessoa.dados'(val){
    //   if(val.length > 0){
    //       // Posiciona o Scrool no começo da tabela 
    //   // this.$vuetify.goTo('.data-table tbody tr',
    //   //                     { offset: 60,
    //   //                       container: '.v-data-table__wrapper'
    //   //                     }
    //   //                   )
    //   }
    // }
  },
 
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else
        return window.innerHeight - 149 - 90;
    }

  },

  mounted(){
    // Seta os privilegios dos elementos da apícãõ inteira
    this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);
  },
  
  methods: {

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    handlePageChange(value) {
      this.store_Pessoa.currentPage = value;
      this.busca_timeout();
    },

    async editar(cod_pessoa) {
      var params = {cod_pessoa : p_pessoa}
      this.store_Pessoa.pessoa_edicao = {};
      this.store_Pessoa.pessoa_edicao = await this.store_Pessoa.PessoaGet(params);
    },

    async excluir(cod_pessoa) {
      this.loading_excluir = true;
      const ls_Resp = await this.store_Pessoa.PessoaDelete({cod_pessoa: cod_pessoa});

      if (ls_Resp.message == "success") {
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = ls_Resp.result;
        this.store_site.alert = true;
      } else {
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = ls_Resp.errors;
        this.store_site.alert = true;
      }
     
      const i = this.store_Pessoa.dados.findIndex(element => element.cod_pessoa == this.item_pessoa_excluir_aux.cod_pessoa)
      if (i !== -1) {
        this.store_Pessoa.dados.splice(i, 1)
      }

      this.loading_excluir = false;
      this.dialog_excluir = false;
    },

    async busca() {
      this.loading = true;
      var lo_params = { page: this.store_Pessoa.currentPage };
      if (this.store_Pessoa.filtro) {
        lo_params.filtro = this.store_Pessoa.filtro;
      }
      const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
      //console.log("lo_Res++++++++++++", lo_Res)
      if (lo_Res && lo_Res.rows.length > 0) {
        // console.log("lo_Res.rows", lo_Res)
        this.store_Pessoa.count_dados = lo_Res.count;
        this.store_Pessoa.dados   = lo_Res.rows;
        this.store_Pessoa.paginas = lo_Res.paginas;
      }
      this.loading = false;
    },

    busca_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.busca(val);
      }, 600);
    },
  },
};
</script>

<style scoped>

/* .v-pagination__item
{
  color: white;
} */
/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.main-title {
  background: var(--accent-color); /* var("--cor-principal"); */
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}


.nav-link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
  border-radius: 25px 25px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.panel-header {
  height: 60px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.v-btn {
  display: inline-block;
}



/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.wrapper-title,
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
}
.search-input-longo {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
  /* min-width: calc(100% - 15px); */
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */

  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.wrapper-title div {
  padding-left: 0px !important;
}

.container-fundo {
  height: calc(100vh -30px) !important;
}

.container-rounded {
  background: #fff !important;
  border-radius: 12px !important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
  padding-left: 27px;
  padding-right: 27px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.row-table-user-tablet {
  display: none;
}

#virtual-scroll-table-tablet {
  display: none;
}


@media (max-width: 1024px) {
  .td-avatar {
    padding-left: 16px !important;
  }

  .td-edit,
  .nav-link {
    padding-right: 0px !important;
    text-decoration: none;
  }

  .status_ativo,
  .status_pendente {
    margin-right: 4px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {

  .container,
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    border-radius: 12px !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .title-page {
    margin-left: 16px !important;
  }

  .wrapper-title div {
    padding-left: 0px !important;
    margin-left: -8px;
  }

  .nav-link {
    padding-left: 0px;
    text-decoration: none;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .avatar {
    margin: 0px 0px 0px 0px !important;
  }
  
  .td-edit-mobile {
    padding-right: 12px !important;
    padding-left: 0px !important;
  }

  .status-mobile {
    display: block;
  }

  .td-status {
    display: none;
  }
 
  .btn-icon {
    margin-right: 0px !important;
  }
  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }
  .container-principal {
    max-width: 100% !important;
  }
}


</style>